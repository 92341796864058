import React, { useState, useEffect, useContext } from 'react';
import './Profile.css';
import { Columns, Form, Button } from "react-bulma-components";
import * as authApi from "../../utils/AuthApi";
import { CurrentUserContext } from "../../context/CurrentUserContext.js";
import Loader from '../Loader/Loader';
import { PROFILE_INFO, COMMON_DATA, SERVICE_DATA } from "../../utils/constants";
function Profile(props) {
    const testTransactions = [
        { date: '2024-04-01', amount: 399, description: 'Подписка', paymentSystem: 'Робокасса' },
        { date: '2024-04-05', amount: 9999, description: 'Тариф Развитие', paymentSystem: 'Робокасса' }
    ]
    const [transactions, setTransactions] = useState(testTransactions)
    const currentUser = useContext(CurrentUserContext);
    const [category, setCategory] = useState(0)
    const [isLoading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    useEffect(() => {
        setFormData({
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            email: currentUser.email,
            phone: currentUser.phone
        });
    }, [currentUser]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const token = localStorage.getItem("token");
        authApi.updateCurrentUser(formData, token)
            .then((res) => {
                if (res.status === 200) {
                    console.log(SERVICE_DATA.successfulUpdateData);
                } else {
                    console.error(`${SERVICE_DATA.error}: ${res.status}`);
                }
            })
            .catch((err) => {
                console.error(`${SERVICE_DATA.error}: ${err}`);
            })
            .finally(() => {
                setLoading(false)
                window.location.reload()
            });
    };

    const handleChangeCategory = (category) => {
        setCategory(category);
    };


    const renderProfileInfo = (
        <form className='transaction__block'>
            {isLoading && <Loader/>}
            <Form.Field>
                <Form.Label className="profile__label">{PROFILE_INFO.firstName}</Form.Label>
                <Form.Control>
                    <Form.Input
                        type="text"
                        className="main__input"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder={PROFILE_INFO.placeholderFirstName}
                    />
                </Form.Control>
            </Form.Field>
            <Form.Field>
                <Form.Label className="profile__label ">{PROFILE_INFO.lastName}</Form.Label>
                <Form.Control>
                    <Form.Input
                        type="text"
                        className="main__input"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder={PROFILE_INFO.placeholderLastName}
                    />
                </Form.Control>
            </Form.Field>
            <Form.Field>
                <Form.Label className="profile__label">{PROFILE_INFO.email}</Form.Label>
                <Form.Control>
                    <Form.Input
                        type="email"
                        className="main__input"
                        name="email"
                        disabled={true}
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={PROFILE_INFO.placeholderEmail}
                    />
                </Form.Control>
            </Form.Field>
            <Form.Field>
                <Form.Label className="profile__label ">{PROFILE_INFO.phoneNumber}</Form.Label>
                <Form.Control>
                    <Form.Input
                        type="tel"
                        className="main__input"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder={PROFILE_INFO.placeholderPhoneNumber}
                    />
                </Form.Control>
            </Form.Field>
                <Form.Field kind="group">
                <Form.Control pt={5}>
                    <a className='profile__submit__button' onClick={handleSubmit}>
                        {isLoading ? COMMON_DATA.saving : COMMON_DATA.saveChanges}
                    </a>
                </Form.Control>
            </Form.Field>
        </form>);

const renderEmptyTransactions = 
    <>
        <p>{COMMON_DATA.emptyData}</p>
        <Button className='main__button-submit'>{PROFILE_INFO.toTariffs}</Button>
    </>

const renderTransactions = 
    <div className='transaction__block'>
        {transactions.map((transaction, index) => (
            <div key={index} className='transaction__info'> 
                <p className="transaction__date">{transaction.date}</p>
                <Columns className="transaction__string" display='flex'>
                    <Columns.Column textColor='black'><span>{transaction.description}</span></Columns.Column>
                    <Columns.Column className="transaction__type" textAlign='center'><span>{transaction.paymentSystem}</span></Columns.Column>
                    <Columns.Column textAlign='right' textColor='black'><span>{transaction.amount}₽ </span></Columns.Column>
                </Columns>
            </div>
        ))}
    </div>
        
const renderServicesInfo = (
    <>
        <p className='transaction_label'>{PROFILE_INFO.transactions}</p>
        {transactions.length === 0 ? renderEmptyTransactions : renderTransactions}
        {}
    </>
    );
  
    return (
    <div className='main'>
      <Columns className='profile'>
        <Columns.Column size={3}>
            <p>
                <a className='header__nav-item' style={category === 0 ? { color: 'black' } : null} onClick={() => handleChangeCategory(0)}>
                    {PROFILE_INFO.profileData}
                </a>
            </p>
            <p>
                <a className='header__nav-item' style={category === 1 ? { color: 'black' } : null} onClick={() => handleChangeCategory(1)}>
                    {PROFILE_INFO.services}
                </a>
            </p>
        </Columns.Column>
        <Columns.Column>
            {category === 0 ? (renderProfileInfo) : (renderServicesInfo)}
        </Columns.Column>
      </Columns>
    </div>
  )
}

export default Profile;